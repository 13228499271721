import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Select } from '../../designSystem/components/select/select';
import { Tab } from '../../designSystem/components/tabs/tab';
import { TabList } from '../../designSystem/components/tabs/tabList';
import { TabPanel } from '../../designSystem/components/tabs/tabPanel';
import { Tabs } from '../../designSystem/components/tabs/tabs';
import { useDesktopQuery } from '../../hooks/useDesktopQuery';
import { useHeavent } from '../../hooks/useHeavent';
import { useTabletQuery } from '../../hooks/useTabletQuery';

interface IRootProps {
    children: React.ReactNode;
    tabsOnTablet?: boolean;
}

export const Root = (props: IRootProps) => {
    const { history } = useHeavent();
    const sections = React.Children.toArray(props.children);
    const isDesktop = useDesktopQuery();
    const isTablet = useTabletQuery();
    const isTabs = isDesktop || (isTablet && props.tabsOnTablet);

    return (
        <>
            {isTabs ? (
                <Tabs height={1}>
                    <TabList>
                        {sections.map((child: any, index: number) => (
                            <Tab key={index} path={child.props.getPath()}>
                                {child.props.title}
                            </Tab>
                        ))}
                    </TabList>

                    <Spacer height="7" />

                    {sections.map((child: any, index: number) => (
                        <TabPanel key={index} path={child.props.getPath(true)}>
                            {child.props.children}
                        </TabPanel>
                    ))}
                </Tabs>
            ) : (
                <>
                    <Select
                        css={{ marginTop: '$4' }}
                        value={location.pathname}
                        onChange={(path) => {
                            history.push(path);
                        }}
                    >
                        {sections.map((child: any, index: number) => (
                            <option key={index} value={child.props.getPath()}>
                                {child.props.title}
                            </option>
                        ))}
                    </Select>

                    <Spacer height="5" />

                    <Switch>
                        {sections.map((child: any, index: number) => (
                            <Route key={index} path={child.props.getPath(true)}>
                                {child.props.children}
                            </Route>
                        ))}
                    </Switch>
                </>
            )}
        </>
    );
};
