import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import {
    useProfileQuery,
    useUserProfileUpdateMutation
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useUserContext } from 'common-front/src/userContext';
import { ProfileTextInputs } from 'common-front/src/users/profile/profileTextInputs';
import { Accept } from 'common-front/src/util/accept';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { IUpdateProfileValues, UserInfoInputService } from 'common/src/input/userInfoInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { DelegationsPaths as DelegationsPathsV2 } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';

export const DelegationProfile = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { data, loader } = useProfileQuery({ organizationId });
    const userInfoInput = useService(UserInfoInputService);
    const validateService = useService(ValidateService);
    const { mutate: updateProfile, isLoading: isUpdateProfileLoading } =
        useUserProfileUpdateMutation();
    const { user } = useUserContext();
    const delegationPath = DelegationsPathsV2.DELEGATION({ organizationId, eventId, delegationId });
    const gotoDelegation = () => history.push(delegationPath);

    return (
        loader || (
            <Form
                direction="column"
                height={1}
                width={1}
                initialValues={{
                    userInfo: userInfoInput.userInfoProfileInputDefault(data.user)
                }}
                validate={validateService.validateForForm(userInfoInput.updateProfileSchema())}
                onSubmit={async (values: IUpdateProfileValues) => {
                    const {
                        userProfileUpdate: { email, language }
                    } = await updateProfile({
                        userId: user!.id,
                        userInfoId: data.user.userInfo.id,
                        userInfo: values.userInfo
                    });

                    if (user!.email !== email || user!.language !== language) {
                        signOut();
                    } else {
                        location.href = delegationPath;
                    }
                }}
                render={({ handleSubmit }) => (
                    <>
                        <Button color="invisible" leftIcon="arrow-left" onClick={gotoDelegation}>
                            {translate('retour_92202')}
                        </Button>

                        <Spacer height="4" />

                        <Box font="gray800 textLg bold">{translate('mise_jour_du_49930')}</Box>

                        <Spacer height="6" />

                        <FormErrors />

                        <FileS3Input
                            acl={'public-read'}
                            accept={Accept.Images}
                            allowCrop={true}
                            label={translate('photo_de_profil_08618')}
                            prefix="userInfo.fields.picture."
                        />

                        <Spacer height="6" />

                        <ProfileTextInputs inputName="userInfo" />

                        <Spacer height="6" />

                        <Flex gap="4">
                            <Button onClick={handleSubmit} isLoading={isUpdateProfileLoading}>
                                {translate('enregistrer_les_30411')}
                            </Button>

                            <Button color="white" onClick={gotoDelegation}>
                                {translate('annuler_48254')}
                            </Button>
                        </Flex>
                    </>
                )}
            />
        )
    );
};
