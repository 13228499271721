import * as React from 'react';

interface ISectionProps {
    children: React.ReactNode;
    title: string;

    getPath(isRouteComponent?: boolean): string;
}

export const Section = (props: ISectionProps) => <>{props.children}</>;
